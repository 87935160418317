<template>
  <div>
    <v-row align="center">
      <v-col cols="auto" class="pr-12">
        <h2>Deposit</h2>
      </v-col>
      <v-col>
        <v-row align="center" justify="end" no-gutters>
          <v-btn small depressed color="green white--text" @click="openForm()">
            <v-icon small left>mdi-plus</v-icon>Edit Deposit
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="8">
        <v-card>
          <v-toolbar flat dense dark color="accent">
            <v-toolbar-title>Deposit</v-toolbar-title>
          </v-toolbar>
          <v-simple-table>
            <thead>
              <tr>
                <th>Deposit</th>
                <th>{{ tour.deposit }}</th>
              </tr>
            </thead>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      scrollable
      max-width="600"
      @click:outside="resetForm()"
    >
      <v-card>
        <v-card-text class="pt-5 grey lighten-4">
          <v-form @submit.prevent="save" method="post" id="deposit-form">
            <v-text-field
              label="Deposit"
              v-model="fields.deposit"
              background-color="white"
              outlined
              :error="errors.hasOwnProperty('deposit')"
              :error-messages="errors['deposit']"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
          <v-btn
            color="accent"
            text
            type="submit"
            :loading="loading"
            form="deposit-form"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      fields: {
        deposit: null,
      },
      errors: {},
    };
  },

  computed: {
    tour() {
      return this.$store.state.drum.tours["tour"];
    },
  },

  methods: {
    openForm() {
      this.fields.deposit = this.tour.deposit;

      this.dialog = true;
    },

    resetForm() {
      this.dialog = false;
      this.loading = false;
      this.fields.deposit = null;

      this.errors = {};
    },

    save() {
      this.loading = true;
      this.errors = {};

      this.$store
        .dispatch("drum/tours/saveDeposit", {
          appId: this.$route.params.id,
          tourId: this.$route.params.tourId,
          fields: this.fields,
        })
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>
